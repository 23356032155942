<template >
    <div v-if="data" class="msp-grafico-confronto" >
        <h4><span v-text="data.etichetta"></span> <template v-if="hasAssegnato">[ <span v-html="data.pctEffettivoStr"></span> ]</template></h4>
        <template v-if="hasAssegnato">
            <figure>
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="report-chart" aria-labelledby="title" role="img">
                    <svg class="grafico-row assegnato" >
                        <rect class="bar" :width="(+data.pctAssegnato || 0) + '%'" height="45%" ></rect>
                    </svg>
                    <svg class="grafico-row effettivo" x="0%" y="50%">
                        <rect class="bar" :width="(+data.pctEffettivo || 0) + '%'" height="45%" :style="'--fill-color: ' + data.colore"></rect>
                    </svg>
                </svg>
            </figure>
            <div class="report-legend">
                <div class="report-legend__item">
                    <div class="legend-box"></div>
                    <span>Assegnato: {{data.valoreAssegnatoStr}}</span>
                </div>
                <div class="report-legend__item">
                    <div :style="'--box-color:' + data.colore" class="legend-box"></div>
                    <span>Effettivo: {{data.valoreEffettivoStr}}</span>
                </div>
            </div>
        </template>
        <template v-if="hasOnlyEffettivo">
        <div class="report-valore" :style="'--box-color:' + data.colore" >
            <p>Effettivo: {{data.valoreEffettivoStr}}</p>
        </div>
        </template>
    </div>
</template>

<script>

const component = {
  props: [
    'data',
  ],
  computed: {
      hasAssegnato: function () {
          if (!this.data) return false;
          return +this.data.valoreAssegnato;
      },
      hasOnlyEffettivo: function () {
          if (!this.data) return false;
          return !this.hasAssegnato && +this.data.valoreEffettivo;
      },
  },
  mounted: function () {
    },
    data() {
        return {
        }
    },
    watch: {
    },
    methods: {
    },
}
export default component;
</script>

<style lang="scss">
.msp-grafico-confronto {
    --height-chart:15px;
    --box-color: #aaaaaa;
    position: relative;
    margin: 10px;
    figure {
        margin:0;
    }
    .titoloSezione {
            width:100%;
            padding:2px;
            line-height:25px;
            background-color: #333;
            color:#fff;
            font-size:0.9em;
            margin-bottom:10px;

    }

    h4 {
        margin: 0;
        padding: 0;
        font-size:0.8em;
        font-weight:bold;
    }
    .report-chart {
        position:relative;
        width: 100%;
        height: var(--height-chart);
    }

    .bar {
        --fill-color: var(--box-color);
        fill: var(--fill-color);
    }



    .labels {
        font-size:0.7em;
        color:#999;
        text-align:center;
    }

    .grid {
      stroke: #a7c9b2;
      stroke-dasharray: 0;
      stroke-width: 1;
    }

    .labels.x-labels {
      text-anchor: middle;
    }

    .labels.y-labels {
      text-anchor: end;
    }

    .report-valore p {
        background-color: var(--box-color);
        color: #fff;
        padding: 2px;
        text-shadow: 1px 1px 2px black;
        font-weight: bold;
    }

    .report-legend {
        position: relative;
          background: #fff;
          top:0;
          font-size: 0.7em;
          height: auto;
          padding: 2px;
          display: block;
        width: 100%;
        &__item {
            display: flex;
            .legend-box {
                background: var(--box-color);
                width: 10px;
                height: 10px;
                margin: 0 5px 0 0;
            }
        }
    }
}

</style>
