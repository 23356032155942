<template>
    <div class="msp-report" :class="classComponent">
        <div class="report-selettore" :style="styleSticky">
            <div class="report-selettore__top">
            <v-btn-toggle 
            center
          v-model="config.period.type"
          mandatory
          @change="changePeriod"
            >
              <v-btn v-for="periodo in periodi" :key="periodo.value" >
                  {{periodo.label}}
              </v-btn>
            </v-btn-toggle>
            <button @click="updatePeriod" class="btn-reload btn btn-icon btn-small btn-flat"><v-icon>mdi-reload</v-icon></button>
        </div>
            <div class="report-selettore__period">
                <v-btn class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="periodPrev" >
                  <v-icon >mdi-chevron-left</v-icon>
                  </v-btn>
                <h3 v-html="currentPeriod"></h3>
                <v-btn class="mx-2"
                  fab
                  dark
                  small
                  color="primary"
                  @click="periodNext" >
                  <v-icon >mdi-chevron-right</v-icon>
                  </v-btn>
            </div>

        </div>
<v-progress-linear
    indeterminate
    v-show="loading"
    color="var(--col-msp)"
    ></v-progress-linear>
    <v-expansion-panels
        class="report-content"
        v-model="panel"
        v-if="report"
        multiple
    >
        <v-expansion-panel>
            <v-expansion-panel-header>
                <h3>Tempo</h3>  
            </v-expansion-panel-header>
            <v-expansion-panel-content >
                <div class="report-row row-riepilogo">
                    <p>totale assegnato: {{MSP().sec2hms(report.totaleTempoAssegnato)}}</p> 
                    <p>totale effettivo: {{MSP().sec2hms(report.totaleTempoEffettivo)}}</p>
                </div>
                <div v-show="hasValues(report.confronto.tempo.PODISMO)" class="report-row row-podismo"><msp-grafico-confronto  :data="report.confronto.tempo.PODISMO"></msp-grafico-confronto></div>
                <div v-show="hasValues(report.confronto.tempo.NUOTO)" class="report-row row-nuoto"><msp-grafico-confronto  :data="report.confronto.tempo.NUOTO"></msp-grafico-confronto></div>
                <div v-show="hasValues(report.confronto.tempo.CICLISMO)" class="report-row row-ciclismo"><msp-grafico-confronto  :data="report.confronto.tempo.CICLISMO"></msp-grafico-confronto></div>
                <div v-show="hasValues(report.confronto.tempo.PALESTRA)" class="report-row row-palestra"><msp-grafico-confronto  :data="report.confronto.tempo.PALESTRA"></msp-grafico-confronto></div>
            </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
            <v-expansion-panel-header>
                <h3>
                    Trimp  
                </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="report-row row-riepilogo">
                    <p>totale assegnato: {{report.totaleTrimpAssegnato}}</p> 
                    <p>totale effettivo: {{report.totaleTrimpEffettivo}}</p>
                </div>
                <div v-show="hasValues(report.confronto.trimp.PODISMO)" class="report-row row-podismo"><msp-grafico-confronto  :data="report.confronto.trimp.PODISMO" ></msp-grafico-confronto></div>
                <div v-show="hasValues(report.confronto.trimp.NUOTO)" class="report-row row-nuoto"><msp-grafico-confronto  :data="report.confronto.trimp.NUOTO" ></msp-grafico-confronto></div>
                <div v-show="hasValues(report.confronto.trimp.CICLISMO)" class="report-row row-ciclismo"><msp-grafico-confronto  :data="report.confronto.trimp.CICLISMO" ></msp-grafico-confronto></div>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <h3>
                    Distanza  
                </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div v-show="hasValues(report.confronto.distanza.PODISMO)" class="report-row row-podismo"><msp-grafico-confronto :data="report.confronto.distanza.PODISMO" ></msp-grafico-confronto></div>
                <div v-show="hasValues(report.confronto.distanza.NUOTO)" class="report-row row-nuoto"><msp-grafico-confronto :data="report.confronto.distanza.NUOTO" ></msp-grafico-confronto></div>
                <div v-show="hasValues(report.confronto.distanza.CICLISMO)" class="report-row row-nuoto"><msp-grafico-confronto :data="report.confronto.distanza.CICLISMO" ></msp-grafico-confronto></div>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <h3>
                    Sessioni  
                </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div v-show="hasValues(report.confronto.sessioni.PODISMO)" class="report-row"><msp-grafico-confronto :data="report.confronto.sessioni.PODISMO" ></msp-grafico-confronto></div>
                <div v-show="hasValues(report.confronto.sessioni.NUOTO)" class="report-row"><msp-grafico-confronto :data="report.confronto.sessioni.NUOTO" ></msp-grafico-confronto></div>
                <div v-show="hasValues(report.confronto.sessioni.CICLISMO)" class="report-row"><msp-grafico-confronto :data="report.confronto.sessioni.CICLISMO" ></msp-grafico-confronto></div>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <h3>
                    Zone di allenamento  
                </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div v-show="hasValues(report.confronto.zone.AEROBICO.tempo)" class="report-row"><msp-grafico-confronto :data="report.confronto.zone.AEROBICO.tempo" ></msp-grafico-confronto></div>
                <div v-show="hasValues(report.confronto.tempo.SOGLIA)" class="report-row"><msp-grafico-confronto :data="report.confronto.tempo.SOGLIA" ></msp-grafico-confronto></div>
                <div v-show="hasValues(report.confronto.zone.FUORI_SOGLIA.tempo)" class="report-row"><msp-grafico-confronto :data="report.confronto.zone.FUORI_SOGLIA.tempo" ></msp-grafico-confronto></div>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <h3>
                    Analisi del sonno
                </h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="report-row"><msp-grafico-sonno :data="report.sonno" ></msp-grafico-sonno></div>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <h3>Potenza critica ciclismo</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div v-show="hasValues({valoreAssegnato:1, valoreEffettivo:1})" class="report-row"><msp-grafico-critical-power :data="report.critical_power.potenza.picco" ></msp-grafico-critical-power></div>
                <div v-show="hasValues({valoreAssegnato:1, valoreEffettivo:1})" class="report-row"><msp-grafico-critical-power :data="report.critical_power.potenza.anaerobico" ></msp-grafico-critical-power></div>
                <div v-show="hasValues({valoreAssegnato:1, valoreEffettivo:1})" class="report-row"><msp-grafico-critical-power :data="report.critical_power.potenza.vo2max" ></msp-grafico-critical-power></div>
                <div v-show="hasValues({valoreAssegnato:1, valoreEffettivo:1})" class="report-row"><msp-grafico-critical-power :data="report.critical_power.potenza.aerobico" ></msp-grafico-critical-power></div>
            </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
            <v-expansion-panel-header>
                <h3>Passo critico podismo</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="report-row"><msp-grafico-critical-power :data="report.critical_power.passo.picco" ></msp-grafico-critical-power></div>
                <div class="report-row"><msp-grafico-critical-power :data="report.critical_power.passo.anaerobico" ></msp-grafico-critical-power></div>
                <div class="report-row"><msp-grafico-critical-power :data="report.critical_power.passo.vo2max" ></msp-grafico-critical-power></div>
                <div class="report-row"><msp-grafico-critical-power :data="report.critical_power.passo.aerobico" ></msp-grafico-critical-power></div>
            </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>

// optionally import default styles
//import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import MSP from "@mixins/msp.js";
import _cal from "@src/js/vue/mixins/calendario.js";
import mspGraficoConfronto from '@src/js/vue/include/MspGraficoConfronto.vue';
import mspGraficoSonno from '@src/js/vue/include/MspGraficoSonno.vue';
import mspGraficoCriticalPower from '@src/js/vue/include/MspGraficoCriticalPower.vue';
import env from "@mixins/environment.js"
import { mapGetters } from 'vuex'
import api from "@mixins/api.js";
const component = {
  mixins: [
    env,
  ],
    components: {
        mspGraficoConfronto,
        mspGraficoSonno,
        mspGraficoCriticalPower,
    },
  props: [
  ],
  computed: {
    ...mapGetters('atleti', [
      "atleta",
    ]),
    ...mapGetters('app', [
      "headerHeight",
    ]),
    styleSticky: function () {
        if (this.headerHeight) {
            return `--top: ${this.headerHeight}px`;
        }
        return "";
    },
          currentPeriod: function () {
            let date = this.config.period.date;
            if (!date) return "";
            let fn = this.periodi[this.config.period.type].labelFunction;
            return this[fn]();
          },
        classComponent: function () {
            let a_c = [];
            if (this.loading) { a_c.push("loading"); }
            return a_c.join(" ");
        },
  },
  mounted: function () {
      this.initPeriod();
    },
    data() {
        return {
            loading: false,
            panel: [0,1,2,3,4,5,6,7],
            config: {
                atleta_id: null,
                period: {
                    type: 0,
                    date: null,
                }
            },
            report: null,
            data_inizio: "2021-05-01",
            data_fine: "2021-05-15",
            periodi: [
                {
                    label: "settimana",
                    value: 0,
                    incFunction: 'incByWeek',
                    labelFunction: 'labelWeek',
                },
                {
                    label: "mese",
                    value: 1,
                    incFunction: 'incByMonth',
                    labelFunction: 'labelMonth',
                },
                {
                    label: "trimestre",
                    value: 2,
                    incFunction: 'incBy3Months',
                    labelFunction: 'label3Months',
                },
            ],
            panels: [
                {
                    active: true,
                },
                {
                    active: true,
                },
                {
                    active: true,
                },
                {
                    active: true,
                },
                {
                    active: true,
                },
                {
                    active: true,
                },
                {
                    active: true,
                },
                {
                    active: true,
                },
                {
                    active: true,
                },
            ],
        }
    },
    methods: {
        hasValues: function (data) {
            if (!data) return false;
            let hasValues = +data.valoreAssegnato || +data.valoreEffettivo;
            return hasValues;
        },

        changePeriod: function () {
            this.config.period.date = _cal.date2ymd();
            this.updatePeriod();

        },

        updatePeriod: function () {
            let fn = this.periodi[this.config.period.type].incFunction;
            this[fn](0);
        },

        initPeriod: function () {
            this.config.period.date = _cal.date2ymd();
            this.updatePeriod();
        },

        init: function () {
            this.loadReport();
        },

        MSP: function () {
          return MSP;
        },

        periodNext: function () { 
            let fn = this.periodi[this.config.period.type].incFunction;
            this[fn](1);

        },

        periodPrev: function () {
            let fn = this.periodi[this.config.period.type].incFunction;
            this[fn](-1);
        },

        labelWeek: function () {
            if (!(this.data_inizio && this.data_fine)) return "";
            let data_from = this.MSP().convertDate(this.data_inizio,"/");
            let data_to = this.MSP().convertDate(this.data_fine,"/");
            return `da ${data_from} <br>a ${data_to}`;
        },

        labelMonth: function () {
            let ymd = this.config.period.date;
            if (!ymd) return "";
            const date = _cal.utcDate(ymd);
            return date.toLocaleString("default",{month:"long", year: "numeric"});
        },

        label3Months: function () {
            if (!(this.data_inizio && this.data_fine)) return "";
            let data_from = this.MSP().convertDate(this.data_inizio,"/");
            let data_to = this.MSP().convertDate(this.data_fine,"/");
            return `da ${data_from} a ${data_to}`;
        },

        incByWeek: function (step) {
            let ymd = this.config.period.date;
            let firstDay;
            let monday = MSP.getMonday(ymd);
            if (step === 1) {
                firstDay = _cal.addDays(monday, 7);
            } else if (step === -1) {
                firstDay = _cal.addDays(monday, -7);
            } else {
                firstDay = monday;
            }

            let lastDay = _cal.addDays(firstDay, 6);
            this.config.period.date = firstDay;
            this.data_inizio = firstDay;
            this.data_fine = lastDay;
            this.updateReport();
        },
        
        incByMonth: function (step) {
            let ymd = this.config.period.date;
            let firstDay;
            if (step === 1) {
                firstDay = _cal.getFirstDayOfNextMonth(ymd);
            } else if (step === -1) {
                firstDay = _cal.getFirstDayOfPrevMonth(ymd);
            } else {
                firstDay = _cal.getFirstDayOfThisMonth(ymd);
            }

            let lastDay = _cal.addDays(_cal.getFirstDayOfNextMonth(firstDay), -1);
            this.config.period.date = firstDay;
            this.data_inizio = firstDay;
            this.data_fine = lastDay;
            this.updateReport();
        },

        incBy3Months: function (step) {
            let ymd = this.config.period.date;
            let firstDay;
            if (step === 1) {
                firstDay = _cal.getFirstDayOfNthMonth(ymd, 3);
            } else if (step === -1) {
                firstDay = _cal.getFirstDayOfNthMonth(ymd, -3);
            } else {
                firstDay = _cal.getFirstDayOfThisMonth(ymd);
            }

            let lastDay = _cal.addDays(_cal.getFirstDayOfNthMonth(firstDay, 3), -1);
            this.config.period.date = firstDay;
            this.data_inizio = firstDay;
            this.data_fine = lastDay;
            this.updateReport();
        },

		setPeriodDate: function (prop, data) { //formato data DD/MM/YYYY
            this.config.period.date = MSP.convertDate(data,"-");
            this.updatePeriod();
		},
        togglePanels: function (ids) {
            ids.forEach(this.togglePanel);
        },
        togglePanel: function (id) {
            let realId = id - 1;
            let active =  !this.panels[realId].active;
            this.panels[realId].active = active;
        },
        classPanel: function (id) {
            let realId = id - 1;
            let isActive = this.panels[realId].active;
            return (isActive) ? "expanded" : "collapsed";
        },

        ready: function () {
            if (!( this.config.period.date
                && this.data_inizio
                && this.data_fine
            )) {
                return false;
            }

            return true;
        },
        updateReport: function () {
            MSP.debounce(this.loadReport, 300)();
        },

        loadReport: function () {
            if (!this.ready()) return false;
            this.loading = true;
            let argAtleta;
            if (this.atleta && this.atleta.id) {
                argAtleta = `& atleta_id=${this.atleta.id}`;
            } else {
                argAtleta = "";
            }
            let url = this.api_base_url+`/api/v2/report.php?data_inizio=${this.data_inizio}&data_fine=${this.data_fine} ${argAtleta}`;

            return new Promise((resolve,reject)=>{
                api(url,'get')
                    .then((result)=>{
                        if (result.success) {
                            this.loading = false;
                            this.report = result.data;
                            resolve(result);
                        } else {
                            resolve(null);
                        }
                    })
                    .catch((error)=>{
                        reject(error);
                    })
            });
        },
        label: function (dato) {
            // TODO conversione della label in base all'UM
            return dato.split;
        },
    },
}
export default component;
</script>

<style lang="scss">
.clickable {
    cursor: pointer;
}
.loading .msp-ui-panel {
    opacity: 0.4;
}
.msp-report {
    --color: #fff;
    .msp-grafico-confronto {
        margin: 0;
    }
    .report-riepilogo {
        background: var(--color); 
        color: #000;
        font-size: 0.8em;
        padding: var(--padding-titoli-negativi);
        p {
            padding: 0;
            margin: 0;
        }

    }
    .report-selettore {
        --top: 0px;
        position: sticky;
        top: var(--top);
        z-index: 10;
        background: #fff;
        padding: 5px 0;
        box-shadow: 0 0 5px rgba(0,0,0,0.5);
        display: grid;
        grid-template-rows: auto auto;

    &__top {
        display: flex;
        justify-content: stretch;
    }

    .btn-reload {
        flex: 1;
    }

    &__period {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding:5px;
        h3 {
            text-align:center;
            line-height: 1;
            font-size: 1.2em;
        }

    }

    }
    &.loading .v-expansion-panel {
        opacity: 0.5;
    }

}
</style>
