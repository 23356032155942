<template >
    <div class="msp-grafico-sonno" >
        <figure>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="report-chart" aria-labelledby="title" role="img">
                <g class="bar ">
                    <rect v-for="(grafico, id) in grafici" :key="id" :width="(+grafico.perc || 0)+'%'" height="100%"  :style="'fill:'+grafico.color" :x="grafico.x+'%'"></rect>
                </g>
                <g class="grid x-grid" id="xGrid">
                    <line x1="0%" x2="0%" y1="0%" y2="100%"></line>
                </g>
                <g class="grid x-grid" id="xGrid">
                    <line x1="25%" x2="25%" y1="0%" y2="100%"></line>
                </g>
                <g class="grid x-grid" id="xGrid">
                    <line x1="50%" x2="50%" y1="0%" y2="100%"></line>
                </g>
                <g class="grid x-grid" id="xGrid">
                    <line x1="75%" x2="75%" y1="0%" y2="100%"></line>
                </g>
                <g class="grid x-grid" id="xGrid">
                    <line x1="100%" x2="100%" y1="0%" y2="100%"></line>
                </g>
                <g class="labels x-labels">
                    <text text-anchor="end" dx="-4px" x="25%" y="100%">25% </text>
                    <text text-anchor="end" dx="-4px" x="50%" y="100%">50% </text>
                    <text text-anchor="end" dx="-4px" x="75%" y="100%">75% </text>
                    <text text-anchor="end" dx="-4px" x="100%" y="100%">100% </text>
                </g>

              
            </svg>	
        </figure>
        <div class="report-legend">
            <div v-for="(grafico, id) in grafici" :key="id" class="report-legend__item">
                <div :style="'--box-color:'+grafico.color" class="legend-box"></div>
                <span>{{grafico.label}} {{grafico.val}} [ {{grafico.perc}}% ]</span>
            </div>
        </div>
        <div class="report-valore"><p>N. Rilevazioni: <span v-text="data.rilevazioni"></span></p></div>
    </div>
</template>

<script>

import MSP from "../../../mobile/src/mixins/msp.js";
import time from "../../../mobile/src/mixins/time.js";

const component = {
  props: [
    'data',
  ],
  computed: {
  },
  mounted: function () {
      this.updateGrafici();
    },
    data() {
        return {
            grafici: {
                "profondo": {
                    label: "Sonno profondo",
                    val: 0,
                    perc: 0, 
                    color: "var(--colore-profondo)",
                    x: 0,
                },
                "leggero": {
                    label: "Sonno leggero",
                    val: 0,
                    perc: 0, 
                    color: "var(--colore-leggero)",
                    x: 0,
                },
                "attivita": {
                    label: "Attivita' sportiva",
                    val: 0,
                    perc: 0, 
                    color: "var(--colore-attivita)",
                    x: 0,
                },
                "altro": {
                    label: "Altro",
                    val: 0,
                    perc: 0, 
                    color: "var(--colore-altro)",
                    x: 0,
                },
},
        }
    },
    watch: {
        data: function () {
            this.updateGrafici();
        }
    },
    methods: {
        MSP: function () {
          return MSP;
        },
        updateGrafici: function () {
            let dati = {
                "profondo": {
                    val: time.sec2hms(+this.data.sonnoProfondo,"hm"),
                    perc: this.data.pctSonnoProfondo,
                },
                "leggero": {
                    val: time.sec2hms(+this.data.sonnoRem + +this.data.sonnoLeggero, "hm"),
                    perc: this.data.pctSonnoLeggero + this.data.pctSonnoRem,
                },
                "attivita": {
                    val: time.sec2hms(+this.data.attivita, "hm"),
                    perc: this.data.pctAttivita,
                },
                "altro": {
                    val: time.sec2hms(+this.data.altro, "hm"),
                    perc: this.data.pctAltro,
                },
            };
            let x = 0;
            for (let key in dati) {
                    this.grafici[key].val = dati[key].val;
                    this.grafici[key].perc = Math.round(dati[key].perc * 10) / 10;
                    this.grafici[key].x = x;
                    x = x + dati[key].perc;
                }
        },
    },

}
export default component;
</script>

<style lang="scss">
.msp-grafico-sonno {
    --box-color: #aaaaaa;
    --height-chart:30px;
    --colore-profondo: #216778;
    --colore-leggero: #5fbcd3;
    --colore-attivita: #ffdd55;
    --colore-altro: #ffd5d5;
    position: relative;
    margin: 10px;
    figure {
        margin:0;
    }
    .report-valore p {
        --box-color: var(--col-grigio-chiarissimo);
        font-size: 0.7em;
        background-color: var(--box-color);
        padding: 2px;
        font-weight: bold;
    }
    .titoloSezione {
            width:100%;
            padding:2px;
            line-height:25px;
            background-color: #333;
            color:#fff;
            font-size:0.9em;
            margin-bottom:10px;

    }

    figcaption {
        font-size:0.8em;
        font-weight:bold;
    }
    .report-chart {
        position:relative;
        width: 100%;
        height: var(--height-chart);
    }

    .bar {
        --fill-color: var(--box-color);
        fill: var(--fill-color);
    }



    .labels {
        font-size:0.7em;
        color:#999;
        text-align:center;
    }

    .grid {
      stroke: #a7c9b2;
      stroke-dasharray: 0;
      stroke-width: 1;
    }

    .labels.x-labels {
      text-anchor: middle;
    }

    .labels.y-labels {
      text-anchor: end;
    }


    .report-legend {
        background: #fff;
        font-size: 0.7em;
        height: auto;
        padding: 2px;
        margin-bottom: 5px;
        display: block;
        width: 100%;
        &__item {
            display: flex;
            .legend-box {
                background: var(--box-color);
                width: 10px;
                height: 10px;
                margin: 0 5px 0 0;
            }
        }
    }
}

</style>
