const time = {
    getFirstDayOfThisMonth: function(date) {
        return time.getFirstDayOfNthMonth(date, 0);
    },

    getFirstDayOfNextMonth: function(date) {
        return time.getFirstDayOfNthMonth(date, 1);
    },

    getFirstDayOfPrevMonth: function(date) {
        return time.getFirstDayOfNthMonth(date, -1);
    },

    getFirstDayOfNthMonth: function(date, inc) {
        return new Date(date.getFullYear(), date.getMonth() + inc, 1);
    },

    sec2hms: function(sec, format) {
      const time = {
        h: Math.floor(sec / 3600) % 24,
        m: Math.floor(sec / 60) % 60,
        s: Math.floor(sec / 1) % 60,
      };
      return Object.entries(time)
        .filter(val => val[1] !== 0)
        .filter(val => format.indexOf(val[0]) !== -1)
        .map(([key, val]) => `${val}${key}`)
        .join('');
    }

};
export default time;
