<template >
    <div v-if="data" class="msp-grafico-critical-power" >
        <figure>
            <figcaption><span v-html="data.cp"></span><br>[ <span v-html="data.pctEffettivoStr"></span> ]</figcaption>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="report-chart" aria-labelledby="title" role="img">
                <svg class="grafico-row assegnato" >
                    <rect class="bar" :width="(+data.pctConfronto || 0) + '%'" height="33%" ></rect>
                </svg>
                <svg class="grafico-row effettivo" x="0%" y="35%">
                    <rect class="bar" :width="(+data.pctPeriodo || 0) + '%'" height="33%" :style="'--fill-color: ' + data.colore"></rect>
                </svg>
                <template v-if="data.gap">
                    <g v-for="(gap, i) in data.gap" :key="i">
                        <g class="grid x-grid" id="xGrid">
                            <line :x1="i * data.pctGap + '%'" :x2="i * data.pctGap + '%'" y1="0%" y2="80%"></line>
                        </g>
                        <g v-if="canShowLabel(i)" class="labels x-labels">
                            <text :x="i * data.pctGap + '%'" y="100%">{{label(i)}}</text>
                        </g>
                    </g>
                </template>
            </svg>
        </figure>
        <div class="report-legend">
            <div class="report-legend__item">
                <div class="legend-box"></div>
                <span>Periodo precedente: {{ data.valoreConfrontoStr }} </span>
            </div>
            <div class="report-legend__item">
                <div :style="'--box-color:' + data.colore" class="legend-box"></div>
                <span>Valori attuale: {{ data.valorePeriodoStr }}</span>
            </div>

        </div>
    </div>
</template>

<script>

import MSP from "../../../mobile/src/mixins/msp.js";

const component = {
  props: [
    'data',
  ],
  computed: {
      steps: function () {
          if (!this.data) return null;
          return this.data.gap;
      }
  },
  mounted: function () {
    },
    data() {
        return {
            limit: 6,
        }
    },
    watch: {
    },
    methods: {
        MSP: function () {
          return MSP;
        },
        canShowLabel: function (index) {
            if (this.steps < this.limit) return true;
            return !(index%2);
        },
        label: function (gap) {
            if (!this.data) {
                return "";
            }
            let label;
            if (this.data.um == 'potenza') {
                label = gap * this.data.split;
            } else if (this.data.um == 'passo') {
                label = this.MSP().sec2hms(gap * this.data.split, true);
            }
            return label;
        },
    },
}
export default component;
</script>

<style lang="scss">
.msp-grafico-critical-power {
    --box-color: #aaaaaa;
    --height-chart:30px;
    position: relative;
    margin: 10px;
    figure {
        margin:0;
    }
    .titoloSezione {
            width:100%;
            padding:2px;
            line-height:25px;
            background-color: #333;
            color:#fff;
            font-size:0.9em;
            margin-bottom:10px;

    }

    figcaption {
        font-size:0.8em;
        font-weight:bold;
    }
    .report-chart {
        position:relative;
        width: 100%;
        height: var(--height-chart);
    }

    .bar {
        --fill-color: var(--box-color);
        fill: var(--fill-color);
    }



    .labels {
        font-size:0.7em;
        color:#999;
    }

    .grid {
      stroke: #a7c9b2;
      stroke-dasharray: 0;
      stroke-width: 1;
    }

    .labels.x-labels text {
      text-anchor: middle;
      fill: #999;
    }

    .labels.y-labels {
      text-anchor: end;
    }


    .report-legend {
        background: #fff;
        font-size: 0.7em;
        height: auto;
        padding: 2px;
        width: 100%;
        &__item {
            display: flex;
            .legend-box {
                background: var(--box-color);
                width: 10px;
                height: 10px;
                margin: 0 5px 0 0;
            }
        }
    }
}

</style>
