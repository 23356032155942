<template>
    <div class="report" >
        <msp-app-bar title="Report"></msp-app-bar>
        <msp-report ></msp-report>
        <msp-app-footer></msp-app-footer>
    </div>
</template>

<script type="module">
import mspReport from "@components/MspReport.vue";
import mspAppBar from "@components/MspAppBar.vue";
import mspAppFooter from "@components/MspAppFooter.vue";


const component = {
  mixins: [
  ],
  components: {
    mspReport,
        mspAppBar,
        mspAppFooter,
  },
    data() {
        return {
            atleta_id: 0,
        }
    },
    methods: {
        dismissModal () {
          this.$store.dispatch("calendario/setDialog", false);
          this.$router.go(-1);
        },
    }
};
export default component;
</script>

<style lang="scss">
    .report {
    }
</style>
